import type { FC, ReactNode, ReactElement } from 'react';
import { useCallback, useMemo, Children } from 'react';

import { typografFixText } from 'utils/typograf';

interface ITypographyFixTextProps {
  children: JSX.Element | string | (string | JSX.Element)[] | ReactNode;
}

function useTypography(): (item: ReactNode) => ReactNode {
  const fixText = useCallback(typografFixText, []);

  return useMemo(() => {
    function t(item: ReactNode): ReactNode {
      // if (Array.isArray(item)) {
      //   return item.map(child => t(child));
      // }

      // if (typeof item === 'string') {
      //   if (item === ' ') {
      //     return item;
      //   }
      //   return fixText(item);
      // } else if (
      //   item &&
      //   typeof item === 'object' &&
      //   (item as ReactElement).props
      // ) {
      //   const reactElement = item as ReactElement;

      //   if (reactElement.type === 'br') {
      //     return reactElement;
      //   }

      //   const hasDangerouslySetInnerHTML =
      //     reactElement.props.dangerouslySetInnerHTML;

      //   return cloneElement(
      //     reactElement,
      //     hasDangerouslySetInnerHTML
      //       ? {}
      //       : {
      //           children: Children.toArray(
      //             reactElement.props.children,
      //           ).map(child => t(child)),
      //         },
      //   );
      // } else {
      return item;
      // }
    }

    return t;
  }, [fixText]);
}

export const Typograf: FC<ITypographyFixTextProps> = ({ children }) => {
  const transform = useTypography();

  return transform(Children.toArray(children)) as ReactElement | null;
};
