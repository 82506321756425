import cn from 'classnames';
import type {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  HTMLAttributeAnchorTarget,
  FC,
} from 'react';

import s from 'components/UIKit/Button/Button.module.scss';
import { CircularProgress } from 'components/UIKit/Loaders';

import type { IIconProps } from '../Icon';
import { Icon } from '../Icon';

export type TButtonSizes = 'small' | 'medium' | 'large';
export type TButtonLooks = 'primary' | 'secondary' | 'outline' | 'tertiary';

interface ICustomProps {
  size?: TButtonSizes | undefined;
  look?: TButtonLooks | undefined;
  href?: string;
  className?: string;
  isLoading?: boolean;
  isDarkSurface?: boolean;
  grow?: boolean;
  icon?: IIconProps['icon'];
  iconSize?: IIconProps['size'];
  iconColor?: IIconProps['color'];
  iconOnly?: boolean;
  target?: HTMLAttributeAnchorTarget;
  dataTestId?: string;
}

export type TButtonProps = ICustomProps &
  ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>;

export const Button: FC<TButtonProps> = props => {
  const {
    look = 'primary',
    size = 'large',
    type = 'button',
    children,
    className,
    disabled,
    isLoading,
    isDarkSurface,
    grow,
    icon,
    iconSize,
    iconColor,
    iconOnly = false,
    href,
    target,
    onClick,
    dataTestId,
  } = props;

  const classes = cn(
    s.button,
    {
      [s[size]]: s[size],
      [s[look]]: s[look],
      [s.grow]: grow,
      [s.isLoading]: isLoading,
      [s.iconOnly]: iconOnly,
      [s.linkDisabled]: href && disabled,
      [s.isDarkSurface]: isDarkSurface,
    },
    className,
  );

  const sizing = size === 'small' ? 16 : 24;

  const innerContent = (
    <>
      {icon && (
        <Icon
          icon={icon}
          size={iconSize ?? sizing}
          color={iconColor}
          className={s.icon}
        />
      )}
      {children}
    </>
  );

  if (href) {
    return (
      <a
        href={!disabled ? href : undefined}
        className={classes}
        role="button"
        onClick={onClick}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        data-testid={dataTestId}
      >
        {isLoading ? <CircularProgress /> : innerContent}
      </a>
    );
  }

  return (
    <button
      className={classes}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      data-testid={dataTestId}
    >
      {isLoading ? <CircularProgress /> : innerContent}
    </button>
  );
};
