import Image from 'next/image';
import { FC } from 'react';

import type { TIllustrationTypes } from './types';

interface IIllustrationProps {
  imageType: TIllustrationTypes;

  className?: string;
  width?: number;
  height?: number;
}

export const Illustration: FC<IIllustrationProps> = ({
  imageType,
  height = 140,
  width = 140,
  className,
}) => {
  return (
    <div className={className}>
      <div style={{ width: width, height: height }}>
        {/*Без этой обертки изображение уменьшается при мобильном разрешении*/}
        <Image
          src={`/components/Illustration/${imageType}.svg`}
          alt={imageType}
          width={width}
          height={height}
        />
      </div>
    </div>
  );
};
