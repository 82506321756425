import type { TUrl } from 'app.types';
import cn from 'classnames';
import type { HTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import { useEffect, useState, FC } from 'react';

import { ALink } from 'components/UIKit/ALink';
import s from 'components/UIKit/FunctionalButton/FunctionalButton.module.scss';
import { Icon } from 'components/UIKit/Icon';
import type { IIconProps } from 'components/UIKit/Icon';

interface IFunctionalButtonProps<T> extends HTMLAttributes<T> {
  icon?: IIconProps['icon'];
  iconSize?: IIconProps['size'];
  iconColor?: IIconProps['color'];
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  isFullWidth?: boolean;
  href?: TUrl;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  children?: ReactNode | string;
  isDarkTheme?: boolean;
  isActive?: boolean;
  isTextBodyM?: boolean;
  onClick?: MouseEventHandler<T>;
  dataTestId?: string;
}

export const FunctionalButton: FC<
  IFunctionalButtonProps<HTMLAnchorElement | HTMLButtonElement>
> = props => {
  const {
    className,
    children,
    iconPosition = 'right',
    iconColor,
    iconSize = 24,
    icon,
    isFullWidth = false,
    href,
    isDarkTheme,
    isActive,
    isTextBodyM,
    type = 'button',
    onClick,
    disabled = false,
    dataTestId,
    ...restProps
  } = props;
  const [isLinkButton, setIsLinkButton] = useState(false);

  useEffect(() => {
    if (href) {
      setIsLinkButton(true);
    }
  }, []);

  const classes = cn(s.root, className, {
    [s.dark]: isDarkTheme,
    [s.darkActive]: isActive,
    [s.iconLeft]: children && iconPosition === 'left',
    [s.iconRight]: children && iconPosition === 'right',
    [s.fullWidth]: isFullWidth,
  });

  const innerContent = (
    <div
      className={cn(s.wrapperButton, {
        [s.fullWidth]: isFullWidth,
      })}
    >
      {icon && iconPosition === 'left' && (
        <Icon icon={icon} size={iconSize} color={iconColor} />
      )}
      {children && (
        <span className={cn(s.text, { [s.bodyM]: isTextBodyM })}>
          {children}
        </span>
      )}
      {icon && iconPosition === 'right' && (
        <Icon icon={icon} size={iconSize} color={iconColor} />
      )}
    </div>
  );

  return (
    <>
      {isLinkButton && href && (
        <ALink href={href} className={classes} dataTestId={dataTestId}>
          {innerContent}
        </ALink>
      )}
      {!href && (
        <button
          // eslint-disable-next-line react/button-has-type
          type={type}
          className={classes}
          onClick={onClick}
          disabled={disabled}
          {...restProps}
          data-testid={dataTestId}
        >
          {innerContent}
        </button>
      )}
    </>
  );
};
