/* eslint-disable @typescript-eslint/naming-convention */
import type { IApiResponseErrorField } from 'app.types';
import type { EBookState } from 'entities/Book/constants';
import type { IEmployee } from 'entities/Employee/types';
import { EPaymentsRegistrationStatus } from 'entities/Payments/constants';

type UserInfoReferralStatus =
  | 'undefined'
  | 'DISABLED'
  | 'OFFER'
  | 'ACTIVE'
  | 'PENDING';

export type BooksByStatus = {
  ebook_status: EBookState;
  count: number;
}[];

export enum EUserRole {
  moderators = 'Moderators',
  mainModerators = 'Main moderators',
  publisher = 'Publisher',
}

export enum EUserOfferModerationState {
  unverified = 'UNVERIFIED',
  verified = 'VERIFIED',
  rejected = 'REJECTED',
}

export enum ETaxState {
  natural_person = 'natural_person',
  self_employed = 'self_employed',
  individual_entrepreneur = 'individual_entrepreneur',
  non_resident = 'non_resident',
}

export interface UserInfoType {
  account_royalty_offer_status: EAccountRoyaltyOfferStatus;
  advanced_royalty_offer_accepted: boolean;
  books_by_status: BooksByStatus;
  email: string;
  has_blank_data: boolean;
  id: number;
  is_apo: boolean;
  is_can_use_editor: boolean;
  is_email_confirmed: boolean;
  is_moderator: boolean;
  is_offer_agreement_accepted: boolean;
  is_upgrade_available: boolean;
  is_publisher?: boolean;
  litres_referral?: UserInfoReferralStatus;
  name: string;
  offer_moderation_status: EUserOfferModerationState;
  roles: EUserRole[];
  referral: ReferralType;
  tax_status: ETaxState;
  employees?: IEmployee[];
  role_types?: EUserRole[];
  has_direct_agreement: boolean;
  rights_owner: string;
  tax_percent: number;
  has_tax_deduction: boolean;
  periodic_report_interval_months: number;
  direct_agreement_document: IDirectAgreementDocumentType;
  litres_account: number;
  resident_country: string;
  uuid: string;
}

export enum EAccountRoyaltyOfferStatus {
  basic = 'basic',
  advanced = 'advanced',
  pending_advanced = 'pending_advanced',
  pending_basic = 'pending_basic',
}

export interface LoginRequestType {
  email?: string;
  password?: string;
}

export interface LoginQueryParamsType {
  _atoken?: string;
}

export interface LoginErrorsType {
  field: string;
  messages: string;
}

interface VerificationPasswordType {
  token: string;
  cache_ttl: number;
}

export interface SendRoyaltyOtcType {
  cache_ttl: number;
  ttl_resending: number;
}

interface CheckRoyaltyOtcType {
  status: string;
  token: string;
}

export interface UserRolesQueryType {
  art_id: string;
  type_id?: number;
  art_type: string;
}

export interface VerificationCodeQueryParamsType {
  token?: string;
}

export interface IDirectAgreementDocumentType {
  id: number;
  number: string;
  document: string;
}

export interface RoyaltyInfoTypes {
  resident_country: 'RU' | string;
  royalty: string;
  available_royalty: string;
  user_status: // Налоговый статус
  | 'NATURAL_PERSON' // - Физическое лицо
    | 'SELF_EMPLOYED' // - Самозанятый
    | 'INDIVIDUAL_ENTREPRENEUR' // - ИП
    | 'NON_RESIDENT' // - Нерезидент, физическое лицо, т.е. страна резидентства НЕ РФ
    | null; // - резидент, не знаем статус
  status_check: // - статусы для физ лиц (проверка бухгалтером)
  | 'NEW' // — Новая
    | 'NOT_AVAILABLE' // — Требует правок
    | 'REQUESTED' // — Запросили данные,
    | 'UPDATED' // — Данные обновлены,
    | 'AVAILABLE' // — Доступна к выплате
    | null; // - заявки не было создано;
  inn: null | string;
  yookassa_status:
    | null // не проходил валидацию через Юкассу
    | 'AwaitingConfirmation' // Ожидает подтверждения
    | 'Confirmed' // Подтверждено
    | 'unregistered' // самозанятый с таким ИНН не зарегистрирован
    | 'notResponding' // сервис Мой налог не отвечает, получателю выплат необходимо заново отравить данные
    | 'internalError'; // внутренняя ошибка ЮKassa
  mandarin_status: EPaymentsRegistrationStatus | null;
  mandarin_card_tail: string;
  mandarin_has_card_token: boolean;
  mandarin_error_message: string;
}

interface ReferralType {
  isDataBroken: boolean;
  totalRoyalty?: number | null;
  availableRoyalty?: number | null;
}

export interface UserInfoState {
  isLogged: boolean;
  is_moderator: boolean;
  is_publisher?: boolean;
  profile: Partial<UserInfoType>;
  errors: IApiResponseErrorField<string>[];
  royaltyInfo: Partial<RoyaltyInfoTypes>;
  referral: Partial<ReferralType>;
  verificationPassword: Partial<VerificationPasswordType>;
  sendRoyaltyOtc: Partial<SendRoyaltyOtcType>;
  checkRoyaltyOtc: Partial<CheckRoyaltyOtcType>;
}

export const taxStatusMap = {
  natural_person: 'Физическое лицо',
  self_employed: 'Самозанятый',
  individual_entrepreneur: 'ИП',
  non_resident: 'Нерезидент, физическое лицо',
};
