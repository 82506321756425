import cn from 'classnames';
import type { FC, HTMLAttributes } from 'react';

import { typografFixText } from 'utils/typograf';

import { Typograf } from '../Typograf';

import s from './Typography.module.scss';

export interface ITypographyProps extends HTMLAttributes<HTMLElement> {
  dataTestId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Tag?: any;
}

const Typography: FC<ITypographyProps> = props => {
  const { Tag = 'div', className, children, ...restProps } = props;
  return (
    <Tag className={cn(s.typography, className)} {...restProps}>
      {Typograf({ children })}
    </Tag>
  );
};

Typography.displayName = '_TypographyCommon';

export const H1: FC<ITypographyProps> = ({ className, ...restProps }) => {
  return <Typography Tag="h1" className={cn(s.h1, className)} {...restProps} />;
};

H1.displayName = 'Typography/Headline 1 (H1)';

export const H2: FC<ITypographyProps> = ({ className, ...restProps }) => {
  return <Typography Tag="h2" className={cn(s.h2, className)} {...restProps} />;
};

H2.displayName = 'Typography/Headline 2 (H2)';

export const H3: FC<ITypographyProps> = ({ className, ...restProps }) => {
  return <Typography Tag="h3" className={cn(s.h3, className)} {...restProps} />;
};

H3.displayName = 'Typography/Headline 3 (H3)';

export const H4: FC<ITypographyProps> = ({ className, ...restProps }) => {
  return <Typography Tag="h4" className={cn(s.h4, className)} {...restProps} />;
};

H4.displayName = 'Typography/Headline 4 (H4)';

export const Subtitle: FC<ITypographyProps> = ({
  className,
  dataTestId,
  ...restProps
}) => {
  return (
    <Typography
      Tag="span"
      className={cn(s.subtitle, className)}
      data-testid={dataTestId}
      {...restProps}
    />
  );
};

Subtitle.displayName = 'Typography/Subtitle (Subtitle)';

export const ButtonM: FC<ITypographyProps> = ({ className, ...restProps }) => {
  return (
    <Typography
      Tag="span"
      className={cn(s.button, s.m, className)}
      {...restProps}
    />
  );
};

ButtonM.displayName = 'Typography/ButtonM (ButtonM)';

export const ButtonS: FC<ITypographyProps> = ({ className, ...restProps }) => {
  return (
    <Typography
      Tag="span"
      className={cn(s.button, s.s, className)}
      {...restProps}
    />
  );
};

ButtonS.displayName = 'Typography/ButtonS (ButtonS)';

export const BodyL: FC<ITypographyProps> = ({ className, ...restProps }) => {
  return (
    <Typography Tag="p" className={cn(s.body, s.l, className)} {...restProps} />
  );
};

BodyL.displayName = 'Typography/BodyL (BodyL)';

export const BodyL700: FC<ITypographyProps> = ({ className, ...restProps }) => {
  return (
    <Typography
      Tag="p"
      className={cn(s.body, s.l, s.bold, className)}
      {...restProps}
    />
  );
};

BodyL700.displayName = 'Typography/BodyL (BodyL700)';

export const BodyL400: FC<ITypographyProps> = ({ className, children }) => {
  return (
    <Typography Tag="p" className={cn(s.body, s.l, s.normal, className)}>
      {children}
    </Typography>
  );
};

BodyL400.displayName = 'Typography/BodyL (BodyL400)';

export const BodyM: FC<ITypographyProps> = ({ className, ...restProps }) => {
  return (
    <Typography Tag="p" className={cn(s.body, s.m, className)} {...restProps} />
  );
};

BodyM.displayName = 'Typography/BodyM (BodyM)';

export const BodyM700: FC<ITypographyProps> = ({ className, ...restProps }) => {
  return (
    <Typography
      Tag="p"
      className={cn(s.body, s.m, s.bold, className)}
      {...restProps}
    />
  );
};

BodyM700.displayName = 'Typography/BodyM (BodyM700)';

export const BodyM400: FC<ITypographyProps> = ({
  className,
  children,
  Tag = 'p',
  ...restProps
}) => {
  return (
    <Typography
      Tag={Tag}
      className={cn(s.body, s.m, s.normal, className)}
      {...restProps}
    >
      {children}
    </Typography>
  );
};

BodyM400.displayName = 'Typography/BodyM (BodyM400)';

export const Caption: FC<ITypographyProps> = ({ className, ...restProps }) => (
  <Typography className={cn(s.caption, className)} {...restProps} />
);

Caption.displayName = 'Typography/Caption (Caption)';

export const Caption400: FC<ITypographyProps> = ({
  className,
  ...restProps
}) => (
  <Typography
    className={cn(s.caption, s.captionNormal, className)}
    {...restProps}
  />
);

Caption.displayName = 'Typography/Caption (Caption400)';

export const Caption700: FC<ITypographyProps> = ({
  className,
  ...restProps
}) => (
  <Typography className={cn(s.caption, s.bold, className)} {...restProps} />
);

Caption700.displayName = 'Typography/Caption (Caption700)';

export const T: FC<{ children: string }> = props => {
  const { children } = props;

  return <>{typografFixText(children)}</>;
};

//TODO: добавить в пример стили таблицы
export const TableTitleB: FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="p"
      className={cn(s.tableTitleB, className)}
      {...restProps}
    />
  );
};

export const TableTitleM: FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="p"
      className={cn(s.tableTitleM, className)}
      {...restProps}
    />
  );
};

export const TableBody: FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography Tag="p" className={cn(s.tableBody, className)} {...restProps} />
  );
};

export const TableCapture: FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="p"
      className={cn(s.tableCapture, className)}
      {...restProps}
    />
  );
};

export const HighlightTitle: FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="h1"
      className={cn(s.highlightTitle, className)}
      {...restProps}
    />
  );
};

export const HighlightSubtitle: FC<ITypographyProps> = ({
  className,
  ...restProps
}) => {
  return (
    <Typography
      Tag="h1"
      className={cn(s.highlightSubtitle, className)}
      {...restProps}
    />
  );
};
