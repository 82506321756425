import TP from 'typograf';

const _typograf = new TP({
  locale: ['ru'],
  disableRule: [
    'common/space/trimRight',
    'common/space/trimLeft',
    'common/space/delTrailingBlanks',
  ],
});

export const typografFixText = (str: string): string => {
  return _typograf.execute(str);
};
